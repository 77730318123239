import React, { useEffect, useState } from "react";
import { Table, Tag, notification,Grid  } from "antd";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import {
  Output,
  WalletTransactionInterface,
} from "../../../services/api/transaction/types";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  transactionActions,
  transactionSelectors,
} from "../../../store/transaction";
import "./style.css";
import { CopyOutlined } from "@ant-design/icons";
import HAVETOApi from "../../../services/api/wallet/api";
import haveToApi from "../../../services/api/transaction/api";
const { useBreakpoint } = Grid;

const WalletTransactions: React.FC = () => {
  const screens = useBreakpoint();
  const [pageSize, setPageSize] = useState<number>(10);
  const [transactionCount, setTransactionCount] = useState<number>(10);
  const [walletAddress, setWalletAddress] = useState("");

  const copyKey = (text: string) => {
    navigator.clipboard.writeText(text);
  };

  const walletTransaction = useAppSelector(
    transactionSelectors.getWalletTransactions
  );
  const loader = useAppSelector(transactionSelectors.getLoader);
  const dispatch = useAppDispatch();

  const getAddress = () => {
    HAVETOApi.userWalletAddress()
      .then((result) => {
        setWalletAddress(result.Address);
      })
      .catch((error) => {
        notification.error({ message: error?.detail.split(":")[1] });
      });
  };

  useEffect(() => {
    getAddress();
  }, []);

  const getTransactionCount = () => {
    haveToApi
      .getWalletTransactionCount({ Address: walletAddress })
      .then((result) => {
        setTransactionCount(result.count);
      })
      .catch((error) => {
        notification.error({ message: error?.detail.split(":")[1] });
      });
  };

  useEffect(() => {
    if (walletAddress) {
      getTransactionCount();
      dispatch(
        transactionActions.getWalletTransactions({
          Address: walletAddress,
          Limit: 10,
          Offset: 0,
        })
      );
    }
  }, [dispatch, pageSize, walletAddress]);

  useEffect(() => {
    const countInterval = setInterval(() => {
      getTransactionCount();
    }, 60000);

    return () => clearInterval(countInterval);
  }, [walletAddress]);

  const paginationProps = {
    showSizeChanger: true,
    pageSize,
    total: transactionCount,
    onShowSizeChange: (current: number, size: number) => {
      setPageSize(size);
      if (walletAddress) {
        dispatch(
          transactionActions.getWalletTransactions({
            Address: walletAddress,
            Limit: size,
            Offset: 0,
          })
        );
      }
    },
    onChange: (page: number, pageSize: number) => {
      if (walletAddress) {
        getTransactionCount();
        dispatch(
          transactionActions.getWalletTransactions({
            Address: walletAddress,
            Limit: pageSize,
            Offset: (page - 1) * pageSize,
          })
        );
      }
    },
  };

  const columns: ColumnsType<WalletTransactionInterface> = [
    {
      key: "hash_key",
      title: "Hash",
      dataIndex: "hash_key",
      className: "max-width-250",
      ellipsis: true,
      render: (hash_key: string) => (
        <div className="text-primary-color">
          <CopyOutlined
            onClick={() => {
              copyKey(hash_key);
            }}
          />
          <a
            href={`https://explorer.stage.haveto.com/explorer/transaction/${hash_key}`}
            target="_blank"
          >
            {`${hash_key}`}
          </a>
        </div>
      ),
      sorter: (a, b) => a.hash_key.localeCompare(b.hash_key),
    },
    {
      key: "block_id",
      title: "Block",
      className: "max-width-250",
      dataIndex: "block_id",
      render: (block_id: string) => (
        <div className="grey">{block_id ? block_id : "-"}</div>
      ),
    },
    {
      key: "inputs",
      title: "Inputs",
      dataIndex: "inputs",
      render: (inputs) => <div className="grey">{inputs?.length}</div>,
    },
    {
      key: "outputs",
      title: "Outputs",
      dataIndex: "outputs",
      render: (outputs) => {
        return <div className="grey">{outputs?.length}</div>;
      },
    },
    {
      key: "transaction_type_",
      title: "Type",
      dataIndex: "transaction_type",
      render: (transaction_type) => {
        return <div className="grey"> {`${transaction_type}`}</div>;
      },
      sorter: (a, b) => a.transaction_type!.localeCompare(b.transaction_type!),
    },
    {
      key: "transaction_status",
      title: "Status",
      dataIndex: "transaction_status",
      render: (transaction_status: string | null) => {
        return <div className="grey"> {`${transaction_status}`}</div>;
      },
      sorter: (a, b) =>
        a.transaction_status!.localeCompare(b.transaction_status!),
    },
    {
      key: "created_at",
      title: "Date-time",
      dataIndex: "created_at",
      render: (created_at: string) => (
        <div className="grey">
          {created_at.endsWith("Z")
            ? moment(new Date(created_at)).format("DD MMM Y ● hh:mm:ss")
            : moment(new Date(created_at + "Z")).format("DD MMM Y ● hh:mm:ss")}
        </div>
      ),
    },
    {
      key: "amount",
      title: "Amount",
      dataIndex: "outputs",
      render: (outputs) => {
        const amount = outputs.reduce(
          (sum: number, output: Output) => sum + Number(output.amount),
          0
        );
        return <div className="grey">{amount}</div>;
      },
    },
  ];
  return (
    <Table
      loading={loader}
      sticky
      scroll={{ x: screens.md ? true : 1000, y: 525 }}
      columns={columns}
      pagination={paginationProps}
      dataSource={walletTransaction}
    />
  );
};

export default WalletTransactions;
