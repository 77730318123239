import { Button, notification } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useRef } from "react";
import QrScanner from "qr-scanner";
interface receiverQRCodeInterface {
    onScan:(data: string) => void;
}
const ReceiverQRCode : React.FC<receiverQRCodeInterface> = ({onScan}) => {
    const fileInputRef  = useRef<HTMLInputElement>(null);
    const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (!file) return;
        event.target.value = "";
        try {
          const imageBitmap = await createImageBitmap(file);
          const result = await QrScanner.scanImage(imageBitmap);
      
          if (result) {
            if (result.startsWith("haveto:")) {
              const walletAddress = result.replace("haveto:", "").trim();
              onScan(walletAddress);
            } else {
              notification.error({ message: "Invalid QR Code Format!" });
            }
          } else {
            notification.error({ message: "No QR Code found!" });
          }
        } catch (error) {
          notification.error({ message: "Error scanning QR Code" });
        }
      };
  return (
    <>
      <input
        type="file"
        accept="image/*"
        onChange={handleFileUpload}
        ref={fileInputRef}
        style={{ display: "none" }}
      />
      <Button
        icon={<UploadOutlined />}
        className={"buy-htc-coin"}
        type="primary"
        style={{ marginTop: "10px" }}
        onClick={() => fileInputRef.current?.click()}
      >
        Upload Receiver QR Code
      </Button>
    </>
  );
};
export default ReceiverQRCode;