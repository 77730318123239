import React, { useEffect, useState } from "react";
import { Skeleton, Image, Space, Button, notification, Divider } from "antd";
import hImg from "../../../assets/user-dashboard/h.png";
import convertImg from "../../../assets/user-dashboard/transfer.png";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { userWalletAction, userWalletSelectors } from "../../../store/wallet";
import { ShoppingCartOutlined } from "@ant-design/icons";
import userSelectors from "../../../store/user/selectors";
import { formatNumber } from "../../../constants/ConstantVar";
import BuyHavetoCoinModal from "../../Payment/BuyHavetoCoinModal";
import HAVETOApi from "../../../services/api/wallet/api";
import TransferHaveToModal from "../../Transfer/TransferHavetoCoinModal";

interface BalanceCardProps {
  data: number;
}
interface TransferProps {
  address: string;
  onModalClose: () => void;
}
const AdminTokenBalanceCard = () => {
  const totalSoldHTC = useAppSelector(userWalletSelectors.getTotalSoldHTC);
  const totalSoldUSD = useAppSelector(userWalletSelectors.getTotalSoldUSD);

  return (
    <>
      <div className="token-balance" >
        <Image src={hImg} className="h-img" preview={false} />
        <Space direction="vertical" size={0}>
          <div className="text-16w">Token Sold</div>
          <div className="text-32w">
            {formatNumber(String(totalSoldHTC.toFixed(2)))}
            <sub className="sub-script-text">HaveTo</sub>
          </div>
        </Space>
      </div>
      <div className="equivalent_usd">
        <Image src={convertImg} className="h-img" preview={false} />
        <Space direction="vertical" size={0}>
          <div className="text-16w">Equivalent value</div>
          <div className="text-32w">
            {formatNumber(String(totalSoldUSD.toFixed(2)))}
            <sub className="sub-script-text">USD</sub>
          </div>
        </Space>
      </div>
    </>
  );
};

const UserTokenBalanceCard = () => {
  const [modalVisible, setModalVisible] = React.useState(false);
  const { total_paid_usd } = useAppSelector(
    userWalletSelectors.fetchuserBalance
  );
  return (
    <>
      <div className="token-balance">
        <Image src={hImg} className="h-img" preview={false} />
        <Space direction="vertical" size={0}>
          <div className="text-16w">Balance in USD</div>
          <div className="text-32w">
            {parseFloat(total_paid_usd!).toFixed(2)}
            <sub className="sub-script-text">USD</sub>
          </div>
        </Space>
      </div>
      <Button
        className="token-balance-btn"
        icon={<ShoppingCartOutlined />}
        onClick={() => setModalVisible(true)}
      >
        Buy HaveTo Token
      </Button>
      <BuyHavetoCoinModal
        visible={modalVisible}
        onClose={() => setModalVisible(false)}
      />
    </>
  );
};

const BalanceCard: React.FC<BalanceCardProps> = ({ data }) => {
  return (
    <>
      <div className="token-balance" style={{ flexDirection: "column"}}>
        <Image src={hImg} className="h-img" preview={false} />
        <Space direction="vertical" size={0} style={{alignItems:"center"}}>
          <div className="text-16w">Balance in Wallet</div>
          <div className="text-32w">
            {Number(data).toFixed(2)}
            <sub className="sub-script-text">HTC</sub>
          </div>
        </Space>
      </div>
    </>
  );
};
const TransferCard: React.FC<TransferProps> = ({ address, onModalClose }) => {
  const [modalVisible, setModalVisible] = React.useState(false);
  return (
    <>
      <div className="token-balance" style={{flexDirection:"column"}}>
        <Image src={hImg} className="h-img" preview={false} />
        <Space direction="vertical" size={0} >
          <div className="text-16w" style={{textAlign:"center"}}>Transfer HTC</div>
        </Space>
        <Button style={{marginTop:"5px", marginLeft:"0px", width:"100%"}}
        className="token-balance-btn"
        onClick={() => setModalVisible(true)}
      >
        Transfer
      </Button>
      </div>
      
      <TransferHaveToModal
        data={address}
        visible={modalVisible}
        onClose={() => {
          setModalVisible(false);
          onModalClose();
        }}
      />
    </>
  );
};
const TokenBalanceCard = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(userSelectors.getUserDetails);
  const loader: boolean = useAppSelector(userWalletSelectors.getLoader);
  const [address, setWalletAddress] = useState("");
  const [Balance, setWalletBalance] = useState(0);
  useEffect(() => {
    getAddress();
  }, []);
  
  useEffect(() => {
    if (address) {
      getBalance();
    }
  }, [address]);
  const getAddress = () => {
    HAVETOApi.userWalletAddress()
      .then((result) => {
        setWalletAddress(result.Address)
      })
      .catch((error) => {
        notification.error({ message: error?.detail.split(":")[1] });
      });
  };
  const getBalance = () =>{
    HAVETOApi.fetchUserWalletBalance({Address: address})
      .then((result) => {
        setWalletBalance(result.Balance)
      })
      .catch((error) =>{
        notification.error({ message: error?.detail.split(":")[1] });
      });
  };
  useEffect(() => {
    if (user.is_staff && user.is_staff !== undefined) {
      dispatch(userWalletAction.totalSoldHTC());
      dispatch(userWalletAction.totalSoldUSD());
    } else if (!user.is_staff && user.is_staff !== undefined) {
      dispatch(userWalletAction.userWallet());
      dispatch(userWalletAction.totalSoldUSD());
    } else {
      return;
    }
  }, [dispatch, user.is_staff]);

  return (
    <>
      <div className="token-balance-card" >
        {loader ? (
          <Skeleton active avatar paragraph={{ rows: 4 }} />
        ) : user.is_staff && user.is_staff !== undefined ? (
          <AdminTokenBalanceCard />
        ) : !user.is_staff && user.is_staff !== undefined ? (
          <UserTokenBalanceCard />
        ) : (
          <Skeleton active avatar paragraph={{ rows: 4 }} />
        )}
      </div>
      <Divider type="vertical" className="token-divider divider-visiblity" />
      <div
        className="token-balance-card"
      >
        <BalanceCard data={Balance} />
      </div>
      <Divider type="vertical" className="token-divider divider-visiblity" />
      <div
        className="token-balance-card"      >
        <TransferCard address={address} onModalClose={getBalance} />
      </div>
    </>
  );
};

export default TokenBalanceCard;
