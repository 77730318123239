import React, { useState } from "react";
import { Row, Col, Card, Tabs, Typography } from "antd";
import AllTransactions from "./transaction_components/AllTransactions";
import UserTransactions from "./transaction_components/UserTransactions";
import WalletTransactions from "./transaction_components/WalletTransactions";
import { useAppSelector } from "../../store/hooks";
import userSelectors from "../../store/user/selectors";

const Transaction = () => {
  const [transactionTab, settransactionTab] = useState("1");
  const {is_staff} = useAppSelector(userSelectors.getUserDetails);

  const renderTableContent = () => {
    switch (transactionTab) {
      case "1":
        return <AllTransactions />;
      case "2":
        return <UserTransactions />;
      case "3":
        return <WalletTransactions />;
      default:
        return <AllTransactions />;
    }
  };

  return (
    <Row className="row-gap">
      <Col span={24}>
        <Card
          title={
            is_staff ? (
              <Typography.Text className="profile-text-color text-16">
                All transactions
              </Typography.Text>
            ) : (
              <Tabs
                defaultActiveKey={transactionTab}
                className="grey-color"
                onChange={(tab) => {
                  settransactionTab(tab);
                }}
              >
                <Tabs.TabPane tab="All transactions" key={1} />
                <Tabs.TabPane tab="My transactions" key={2} />
                <Tabs.TabPane tab="Wallet transactions" key={3} />
              </Tabs>
            )
          }
          className={"transaction-card"}
        >
          {renderTableContent()}
        </Card>
      </Col>
    </Row>
  );
};

export default Transaction;
