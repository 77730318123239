import { call, getContext, put, takeLeading } from "redux-saga/effects";
import { actionTypes } from "./actions";
import { PayloadAction } from "@reduxjs/toolkit";
import { HAVETOApi } from "../../services/api";
import { transactionActions } from "./actions";
import { notification } from "antd";
import { TransactionState } from "./reducer";
import { TransactionPayload, WalletTransactionPayload, } from "../../services/api/transaction/types";

const { getAllTransactionsSuccess,
  getAllTransactionsFailure,
  getUserTransactionsFailure,
  getUserTransactionsSuccess,
  getWalletTransactionsFailure,
  getWalletTransactionsSuccess,
} = transactionActions;

function* getAllTransactionsFlow(action: PayloadAction<TransactionPayload>) {
  const api: HAVETOApi = yield getContext("api");
  const { transaction, error } = yield call(api.transaction.getAllTransactions,action.payload);
  if (error) {
    yield put(getAllTransactionsFailure({ transaction: null, error }));
  } else {
    yield put(getAllTransactionsSuccess({ transaction, error: null }));
  }
}

function* getUserTransactionsFlow(action: PayloadAction<TransactionPayload>) {
  const api: HAVETOApi = yield getContext("api");
  const { userTransaction, error } = yield call(api.transaction.getUserTransactions,action.payload);
  if (error) {
    yield put(getUserTransactionsFailure({ userTransaction: null, error }));
  } else {
    yield put(getUserTransactionsSuccess({ userTransaction, error: null }));
  }
}

function* getWalletTransactionsFlow(
  action: PayloadAction<WalletTransactionPayload>
) {
  const api: HAVETOApi = yield getContext("api");
  const { walletTransaction, error } = yield call(
    api.transaction.getUserWalletTransactions,
    action.payload
  );
  if (error) {
    yield put(
      getWalletTransactionsFailure({ walletTransaction: null, error })
    );
  } else {
    yield put(
      getWalletTransactionsSuccess({ walletTransaction, error: null })
    );
  }
}

export default function* transactionSaga() {
  // Calling API All transaction
  yield takeLeading(actionTypes.GetAllTransactions, getAllTransactionsFlow);
  // Do Action when Failure called transactions
  yield takeLeading(
    actionTypes.GetAllTransactionsFailure,
    function* (action: PayloadAction<TransactionState>) {
      yield notification.error({
        message:
          action.payload.error?.message?.detail || "Something went wrong",
      });
    }
  );
  // Calling API get user transactions
  yield takeLeading(actionTypes.GetUserTransactions, getUserTransactionsFlow);
  // Do Action when Failure called user transactions
  yield takeLeading(
    actionTypes.GetUserTransactionsFailure,
    function* (action: PayloadAction<TransactionState>) {
      yield notification.error({
        message:
          action.payload.error?.message?.detail || "Something went wrong",
      });
    }
  );

  // Calling API get user wallet transactions
  yield takeLeading(
    actionTypes.GetWalletTransactions,
    getWalletTransactionsFlow
  );
  // Do Action when Failure called user wallet transactions
  yield takeLeading(
    actionTypes.GetWalletTransactionsFailure,
    function* (action: PayloadAction<TransactionState>) {
      yield notification.error({
        message:
          action.payload.error?.message?.detail || "Something went wrong",
      });
    }
  );
}
