import React, { useEffect, useRef, useState } from "react";
import { Card, Slider, Space } from "antd";
import { SliderMarks } from "antd/lib/slider";
import { SALES_PROGRESS_DUE_DATE } from "../../../constants/ConstantVar";
import { InfoCircleFilled } from "@ant-design/icons";
import { useAppSelector } from "../../../store/hooks";
import { userWalletSelectors } from "../../../store/wallet";

const SaleProgress = () => {
  const Ref = useRef(null);

  const totalSoldUSD = useAppSelector(userWalletSelectors.getTotalSoldUSD);
  const [timer, setTimer] = useState<string>("00:00:00:00");
  const getTimeRemaining = (e: number) => {
    const today = Date.now();
    const difference = e - today;
    const total = difference;
    const timestamp = total / 1000;
    const seconds = Math.floor((timestamp) % 60);
    const minutes = Math.floor((timestamp / 60) % 60);
    const hours = Math.floor((timestamp / 60 / 60) % 24);
    let day = Math.floor(timestamp / 60 / 60 / 24);
    return {
      total,
      hours,
      minutes,
      seconds,
      day,
    };
  };

  const startTimer = (endDate: Date) => {
    let { total, day, hours, minutes, seconds } = getTimeRemaining(Number(endDate));
    if (total >= 0) {
      // update the timer
      // check if less than 10 then we need to
      // add '0' at the beginning of the variable
      setTimer(
        (day > 9 ? day : "0" + day) +
        ":" +
        (hours > 9 ? hours : "0" + hours) +
        ":" +
        (minutes > 9 ? minutes : "0" + minutes) +
        ":" +
        (seconds > 9 ? seconds : "0" + seconds)
      );
    }
  };

  useEffect(() => {
    // clearTimer(new Date(SALES_PROGRESS_DUE_DATE));
    startTimer(new Date(SALES_PROGRESS_DUE_DATE));
    if (Ref.current) clearInterval(Ref.current);
    setInterval(() => {
      startTimer(new Date(SALES_PROGRESS_DUE_DATE));
    }, 1000);
  });
  const time = timer.split(":");
  const marks: SliderMarks = {
    0: {
      style: {
        color: "#888888",
      },
      label: <strong className="strong">0</strong>,
    },
    // 25: {
    //   style: {
    //     color: "#888888",
    //   },
    //   label: (
    //     <strong>
    //       Soft cap
    //       <br /> 40,000
    //     </strong>
    //   ),
    // },
    // 80: {
    //   style: {
    //     color: "#888888",
    //   },
    //   label: (
    //     <strong>
    //       Hard cap
    //       <br /> 1,20,500
    //     </strong>
    //   ),
    // },
    100: {
      style: {
        color: "#888888",
        left: "90%",
      },
      label: <strong className="strong">150,000,000,000</strong>,
    },
  };
  return (
    <Card title="Sale progress" className="sales-progress">
      <Space direction="vertical" size={50} className="sales-progress-padding">
        <div className="sales-slider">
          
          <div className="sales-progress-text-14 float-left">
            Raised
            <p className="text-16">{totalSoldUSD.toFixed(2)}</p>
          </div>
          <div className="sales-progress-text-14 float-right">
            Total
            <p className=" text-16">150 Million USD</p>
          </div>
          <br />
          <br />
          {
            (totalSoldUSD !== null) && (
              <Slider
                disabled
                tooltipVisible={false}
                marks={marks}
                step={10}
                defaultValue={(totalSoldUSD || 0) * 100 / 150000000000}
              />
            )
          }
        </div>

      {/* <div>
          <p className="text-16 text-white-color">Sale ends in</p>
          <div className="sales-box-container">
            <div className="sales-counter-box">
              <div className="text-24">{time[0]}</div>
              <div className="sales-progress-text-14">Day</div>
            </div>
            <div className="sales-counter-box">
              <div className="text-24">{time[1]}</div>
              <div className="sales-progress-text-14">Hour</div>
            </div>
            <div className="sales-counter-box">
              <div className="text-24">{time[2]}</div>
              <div className="sales-progress-text-14">Min</div>
            </div>
            <div className="sales-counter-box">
              <div className="text-24">{time[3]}</div>
              <div className="sales-progress-text-14">Sec</div>
            </div>
          </div> 
        </div> */}
        <span className="text-12">
          <InfoCircleFilled />
          &nbsp;Sales progress calculted based on current sales order
        </span>
      </Space>
    </Card>
  );
};

export default SaleProgress;
