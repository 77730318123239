import { createSlice } from "@reduxjs/toolkit";
import { TransactionCountInterface, UserTransactionInterfaceWithPaging, WalletTransactionInterface, } from "../../services/api/transaction/types";
import { HAVETOApiError } from "../../services/api/types";
import { transactionActions } from "./actions";

export interface TransactionState {
  transaction: TransactionCountInterface;
  userTransaction: UserTransactionInterfaceWithPaging;
  walletTransaction: WalletTransactionInterface[];
  loaders: boolean;
  error: HAVETOApiError | null;
}

const initialState: TransactionState = {
  transaction: {count:0,results:[]},
  userTransaction:{count:0,results: []},
  walletTransaction: [],
  loaders: false,
  error: null,
};

const transactionSlice = createSlice({
  name: "transaction",
  initialState,
  reducers: {
    // reducers property both creates an action creator function and responds to that action in the slice reducer
  },
  extraReducers: {
    // The extraReducers allows you to respond to an action in your slice reducer but does not create an action creator function
    [transactionActions.getAllTransactions.type]: (state) => {
      state.loaders = true;
    },
    [transactionActions.getAllTransactionsSuccess.type]: (state, action) => {
      state.loaders = false;
      state.transaction = action.payload.transaction;
    },
    [transactionActions.getAllTransactionsFailure.type]: (state, action) => {
      state.loaders = false;
      state.error = action.payload.error;
    },

    [transactionActions.getUserTransactions.type]: (state) => {
      state.loaders = true;
    },
    [transactionActions.getUserTransactionsSuccess.type]: (state, action) => {
      state.loaders = false;
      state.userTransaction = action.payload.userTransaction;
    },
    [transactionActions.getUserTransactionsFailure.type]: (state, action) => {
      state.loaders = false;
      state.error = action.payload.error;
    },

    [transactionActions.getWalletTransactions.type]: (state) => {
      state.loaders = true;
    },
    [transactionActions.getWalletTransactionsSuccess.type]: (state, action) => {
      state.loaders = false;
      state.walletTransaction = action.payload.walletTransaction;
    },
    [transactionActions.getWalletTransactionsFailure.type]: (state, action) => {
      state.loaders = false;
      state.error = action.payload.error;
    },
  },
});

export default transactionSlice.reducer;
