import {
  Modal,
  Form,
  Input,
  Typography,
  Row,
  Col,
  Button,
  notification,
} from "antd";
import { useState, useEffect } from "react";
import { SwapOutlined } from "@ant-design/icons";
import haveToAPI from "../../services/api/Transfer/api";
import SenderQRCode from "../QRCode/SenderQRCode";
import UploadQRCode from "../QRCode/UploadQRCode";

interface TransferHaveToModalInterface {
  visible: boolean;
  onClose: () => void;
  data: string;
}

const TransferHaveToModal: React.FC<TransferHaveToModalInterface> = ({
  visible,
  onClose,
  data,
}) => {
  const [appForm] = Form.useForm();
  const [loader, setLoader] = useState<boolean>(false);
  const [isQRVisible, setisQRVisible] = useState(true);
  const [address, setAddress] = useState("");
  const handleCancel = () => {
    appForm.resetFields();
    setAddress("");
    onClose();
  };
  const closeQRModal = () => {
    setisQRVisible(false);
  };
  const HandleSubmit = () => {
    appForm.setFieldsValue({
      receiverAddress: address
    });
    appForm
      .validateFields()
      .then((values) => {
        setLoader(true);
        const rawAmount = values.amount.toString();
        const numAmount = parseFloat(rawAmount);
        const formattedAmount =
          rawAmount.indexOf(".") === -1 ? numAmount.toFixed(1) : rawAmount;
        haveToAPI
          .transferWalletBalance({
            receiverAddress: address,
            senderAddress: data,
            Amount: formattedAmount,
            gasFee: values.gasFee,
            priorityFee: values.priorityFee,
          })
          .then((response) => {
            notification.success({
              message: "Balance transferred successfully.",
            });
            appForm.resetFields();
            setAddress('');
            onClose();
          })
          .catch((error) => {
            notification.error({ message: "Something went wrong." });
          })
          .finally(() => {
            setLoader(false);
          });
      })
      .catch((info) => {
        notification.error({ message: `Validation Failed: ${info}` });
      });
  };

  useEffect(() => {
    setisQRVisible(true);
    if (visible) {
      appForm.resetFields();
    }
  }, [visible, appForm]);
  return (
    <Modal
      style={{ background: "white" }}
      visible={visible}
      title={"Transfer HTC"}
      centered={true}
      onCancel={handleCancel}
      footer={null}
    >
      <Form form={appForm} layout="vertical">
        <Row gutter={[0, 10]} align="middle" justify="center">
          <Col span={24}>
            <Form.Item
              name="receiverAddress"
              label={
                <Typography.Text className="text-black-color">
                  Receiver Address
                </Typography.Text>
              }
              rules={[
                { required: true, message: "Receiver Address is required" },
              ]}
            >
              <Input
                placeholder="Receiver Address"
                className="email-input"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />

              <label className="transfer-or-option">OR</label>
            </Form.Item>
            <div
              className="row transfer-row"
            >
              <UploadQRCode
                onScan={(data: string) => {
                  appForm.setFieldsValue({ receiverAddress: data });
                  setAddress(data);
                }}
              ></UploadQRCode>
            </div>
            <SenderQRCode
              visible={isQRVisible}
              address={data}
              onClose={closeQRModal}
            ></SenderQRCode>
          </Col>
          <Col span={24}>
            <Form.Item
              name="amount"
              label={
                <Typography.Text className="text-black-color">
                  Amount
                </Typography.Text>
              }
              rules={[{ required: true, message: "Amount is required" }]}
            >
              <Input
                placeholder="Amount"
                className="email-input"
                type="number"
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="gasFee"
              label={
                <Typography.Text className="text-black-color">
                  Max fee per Gas
                </Typography.Text>
              }
              rules={[{ required: true, message: "Gas fee is required" }]}
            >
              <Input
                placeholder="Gas fee"
                className="email-input"
                type="number"
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="priorityFee"
              label={
                <Typography.Text className="text-black-color">
                  Max Priority fee per Gas
                </Typography.Text>
              }
              rules={[{ required: true, message: "Priority fee is required" }]}
            >
              <Input
                placeholder="Priority fee"
                className="email-input"
                type="number"
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label={" "}>
              <Button
                className={"buy-htc-coin"}
                onClick={HandleSubmit}
                loading={loader}
                icon={<SwapOutlined />}
              >
                Transfer HTC
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default TransferHaveToModal;
