import { API } from "../../allApi";
import { haveToApiService } from "../../plugins/axios";
import { TokenInterface } from "../user/types";
import { fetchUserBalancePayload, TokenConversionPayload, UpdateTokenConversionPayload, UserWalletApiInterface,  UserWalletResources } from "./types";
import jwt_decode from "jwt-decode";

const WALLET_ENDPOINTS: UserWalletResources = {
  // create subscription get notified
  FETCH_USER_WALLET: {
    URL: API.USER_WALLET.URL,
    METHOD: API.USER_WALLET.METHOD,
  },
  TOKEN_CONVERSION: {
    URL: API.TOKEN_CONVERSION.URL,
    METHOD: API.TOKEN_CONVERSION.METHOD,
  },
  TOTAL_SOLD_HTC_COIN:{
    URL: API.TOTAL_SOLD_HTC_COIN.URL,
    METHOD: API.TOTAL_SOLD_HTC_COIN.METHOD,
  },
  TOTAL_SOLD_USD:{
    URL: API.TOTAL_SOLD_USD.URL,
    METHOD: API.TOTAL_SOLD_USD.METHOD,
  },
  UPDATE_TOKEN_PRICE:{
    URL: API.UPDATE_TOKEN_CONVERSION.URL,
    METHOD: API.UPDATE_TOKEN_CONVERSION.METHOD,
  },
  FETCH_USER_WALLET_ADDRESS:{
    URL: API.FETCH_WALLET_ADDRESS.URL,
    METHOD: API.FETCH_WALLET_ADDRESS.METHOD,
  },
  FETCH_USER_WALLET_BALANCE:{
    URL: API.FETCH_WALLET_BALANCE.URL,
    METHOD: API.FETCH_WALLET_BALANCE.METHOD,
  },
};

const HAVETOApi: UserWalletApiInterface = {
  fetchUserBalance: async () => {
    const decodedToken: TokenInterface = jwt_decode(localStorage.getItem('authToken')!);
    const { response, error } = await haveToApiService({
      resource: WALLET_ENDPOINTS.FETCH_USER_WALLET,
      headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` },
      options: {
        pathVars:{user_id:decodedToken.user_id}
      },
    });
    if (error) {
      return { error, wallet: null };
    } else {
      return {
        wallet: response,
        error: null,
      };
    }
  },
  tokenConversion: async (payload:TokenConversionPayload) => {
    const htc_coin = payload.htc
    const { response, error } = await haveToApiService({
      resource: WALLET_ENDPOINTS.TOKEN_CONVERSION,
      headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` },
      options: {
        queryParams: {
          htc:htc_coin
        },
      },
    });
    if (error) {
      return { error, tokenConversion: null };
    } else {
      return {
        tokenConversion: response,
        error: null,
      };
    }
  },
  updateTokenConversionPrice: async (payload:UpdateTokenConversionPayload) => {
    const { response, error } = await haveToApiService({
      resource: WALLET_ENDPOINTS.UPDATE_TOKEN_PRICE,
      headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` },
      options: {
        data: {
          usd_price : payload.usd_price,
          eth_price : payload.eth_price,
        },
        pathVars: {
          id:payload.id
        },
      },
    });
    if (error) {
      return { error, tokenConversion: null };
    } else {
      return {
        tokenConversion: response,
        error: null,
      };
    }
  },
  totalSoldHTCCoin: async () => {

    const { response, error } = await haveToApiService({
      resource: WALLET_ENDPOINTS.TOTAL_SOLD_HTC_COIN,
      headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` },

    });
    if (error) {
      return { error, totalSoldHtc: null };
    } else {
      return {
        totalSoldHtc: response.total_htc_coin__sum,
        error: null,
      };
    }
  },
  totalSoldUSD: async () => {
    const { response, error } = await haveToApiService({
      resource: WALLET_ENDPOINTS.TOTAL_SOLD_USD,
      headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` },
      
    });
    if (error) {
      return { error, totalSoldUsd: null };
    } else {
      return {
        totalSoldUsd: response.total_usd,
        error: null,
      };
    }
  },
  userWalletAddress: async () => {
    const { response, error } = await haveToApiService({
      resource: WALLET_ENDPOINTS.FETCH_USER_WALLET_ADDRESS,
      headers: { Authorization: `Bearer ${localStorage.getItem("userAuthToken")}` },
    });
    if (error || !response?.[0]?.address) {
      return { error: error || "Address not found", Address: "" };
    } else {
      return {
        Address: response[0].address,
        error: null,
      };
    }
  },
  fetchUserWalletBalance: async (payload: fetchUserBalancePayload) => {
    const {response, error} = await haveToApiService({
      resource: WALLET_ENDPOINTS.FETCH_USER_WALLET_BALANCE,
      options : {
        pathVars : {public_address: payload.Address}
      }
    });
    if (error) {
      return { error, Balance: 0 };
    } else {
      return {
        Balance: response.balance,
        error: null,
      };
    }
  }
};

export default HAVETOApi;

export { WALLET_ENDPOINTS };
