import { API } from "../../allApi";
import { haveToApiService } from "../../plugins/axios";
import { TransferWalletResources, TransferWallet, TransferBalanceRequest, TransferBalanceResponse } from "./types";

const TRANSFER_ENDPOINTS: TransferWalletResources =  {
    TRANSFER_BALANCE : {
        URL: API.TRANSFER_WALLET_BALANCE.URL,
        METHOD: API.TRANSFER_WALLET_BALANCE.METHOD
    },
};
const haveToAPI : TransferWallet =  {
    transferWalletBalance: async (payload:TransferBalanceRequest) => {
        const {response, error} = await haveToApiService({
            resource: TRANSFER_ENDPOINTS.TRANSFER_BALANCE,
            headers: { Authorization: `Bearer ${localStorage.getItem("userAuthToken")}` },
            options: {
                data: {
                    sender_address: payload.senderAddress,
                    receiver_address: payload.receiverAddress,
                    amount: payload.Amount,
                    max_fee_per_gas: payload.gasFee,
                    max_priority_fee_per_gas: payload.priorityFee,
                    data: "string",
                    transaction_type: "TRANSFER"
                },
            },
        });
        if(error){
            return {error: error}
        }
        else{
            return {error:null}
        }
    },
}
export default haveToAPI;
export {TRANSFER_ENDPOINTS};