import { Modal, Button, notification } from "antd";
import QRCode from "react-qr-code";
import { DownloadOutlined } from "@ant-design/icons";
import { useState, useRef } from "react";
interface SenderQRCodeInterface {
    visible: boolean;
    onClose: () => void;
    address: string;
}


const GenerateQRCode: React.FC<SenderQRCodeInterface> = ({visible, onClose, address}) => {
    //const [isQRVisible, setQRVisible] = useState(false);
    const closeQRModal = () => {
        onClose();
    }
    const qrRef = useRef<HTMLDivElement>(null);
    const downloadQRCode = () => {
        const svg = qrRef.current?.querySelector("svg");
        if (svg) {
          const svgData = new XMLSerializer().serializeToString(svg);
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");
          const img = new Image();
          img.onload = () => {
            canvas.width = img.width;
            canvas.height = img.height;
            if (ctx !== null) {
              ctx.drawImage(img, 0, 0);
            }
            const pngUrl = canvas.toDataURL("image/png");
            const downloadLink = document.createElement("a");
            downloadLink.href = pngUrl;
            downloadLink.download = `{Address}_qrcode.png`;
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
          };
          img.src = "data:image/svg+xml;base64," + btoa(svgData);
        } else {
          notification.error({ message: "Something went wrong." });
        }
      };
    return (
        <Modal
                title="Your Wallet QR Code"
                visible={visible}
                centered
                onCancel={closeQRModal}
                footer={[
                  <Button
                    className={"buy-htc-coin"}
                    key="download"
                    type="primary"
                    icon={<DownloadOutlined />}
                    onClick={downloadQRCode}
                  >
                    Download QR Code
                  </Button>,
                  <Button
                    className={"buy-htc-coin"}
                    key="close"
                    onClick={closeQRModal}
                  >
                    Close
                  </Button>,
                ]}
              >
                <div ref={qrRef} style={{ textAlign: "center" }}>
                  <QRCode value={`haveto:${address}`} size={250} />
                </div>
              </Modal>
    )
}

export default GenerateQRCode